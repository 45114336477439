<section class="welcome-area hero2" id="home">
	<!-- Background Shape-->
	<div class="background-shape">
		<div class="circle1 wow fadeInRightBig" data-wow-duration="4000ms"></div>
		<div class="circle2 wow fadeInRightBig" data-wow-duration="4000ms"></div>
		<div class="circle3 wow fadeInRightBig" data-wow-duration="4000ms"></div>
		<div class="circle4 wow fadeInRightBig" data-wow-duration="4000ms"></div>
	</div>
	<!-- Background Animation-->
	<!-- <div class="background-animation">
		<div class="star-ani"></div>
		<div class="cloud-ani"></div>
		<div class="triangle-ani"></div>
		<div class="circle-ani"></div>
		<div class="box-ani"></div>
	</div> -->
	<div class="container h-100">
		<div class="row h-100 align-items-center justify-content-between">


			<!-- Welcome Content-->
			<div class="welcome-content">
				<div class="about-content">
					<div class="row justify-content-between">
						<!-- Single About Area-->
						<div class="col-12 col-sm-6 col-lg-3">
							<div class="single-about-area text-center wow fadeInUp" data-wow-delay="100ms" data-wow-duration="1000ms">
								<div class="icon mx-auto">
									<i class="lni lni-code-alt"></i>
								</div>
								<h4>DEVELOPMENT</h4>
								<p>We design and develop software applications that can be implemented and inter-operate on multiple computing platforms.</p>
							</div>
						</div>
						<!-- Single About Area-->
						<div class="col-12 col-sm-6 col-lg-3">
							<div class="single-about-area text-center wow fadeInUp" data-wow-delay="300ms" data-wow-duration="1000ms">
								<div class="icon mx-auto">
									<i class="lni lni-handshake"></i>
								</div>
								<h4>CONSULTING</h4>
								<p>We understand the nature of your business and provide you with the best resources available. </p>
							</div>
						</div>
						<!-- Single About Area-->
						<div class="col-12 col-sm-6 col-lg-3">
							<div class="single-about-area text-center wow fadeInUp" data-wow-delay="500ms" data-wow-duration="1000ms">
								<div class="icon mx-auto">
									<i class="lni lni-invention"></i>
								</div>
								<h4>BESPOKE
								</h4>
								<p>We gauge our clients pulse, analyse and offer customized and best possible solution that are not readily available in off the shelf products.

								</p>
							</div>
						</div>
						<!-- Single About Area-->
						<div class="col-12 col-sm-6 col-lg-3">
							<div class="single-about-area text-center wow fadeInUp" data-wow-delay="700ms" data-wow-duration="1000ms">
								<div class="icon mx-auto">
									<i class="lni lni-stats-up"></i>
								</div>
								<h4>DATA ANALYTICS
								</h4>
								<p>We develop applications that help organizations to examine the raw data and visualize information to enhance the business model.

								</p>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>
</section>






<!-- feature area -->
<div class="container">
	<div class="border-top"></div>
</div>

<!-- facts area -->
<section class="saasbox-cool-facts-area section-padding-120-70">
	<!-- Circle Animation-->
	<div class="circle-animation">
		<div class="circle1"></div>
		<div class="circle2"></div>
		<div class="circle3"></div>
		<div class="circle4"></div>
	</div>
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-12 col-lg-8">
				<div class="section-heading text-center">
					<h2>We already completed <span> 100 </span> projects successfully and more counting.</h2>
				</div>
			</div>
		</div>
	</div>
	<div class="container">
		<div class="row">
			<!-- Single Cool Facts Area-->
			<div class="col-12 col-sm-4">
				<div class="single-cool-fact mb-50 wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="100ms">
					<h2><span class="rs-counter">100 </span>+</h2>
					<p>Total Projects</p>
				</div>
			</div>
			<!-- Single Cool Facts Area-->
			<div class="col-12 col-sm-4">
				<div class="single-cool-fact mb-50 wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="300ms">
					<h2><span class="rs-counter">21</span></h2>
					<p>Team Members</p>
				</div>
			</div>
			<!-- Single Cool Facts Area-->
			<div class="col-12 col-sm-4">
				<div class="single-cool-fact mb-50 wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="500ms">
					<h2><span class="rs-counter">3618</span></h2>
					<p>Coffee Served</p>
				</div>
			</div>
		</div>
	</div>
</section>
<div class="container">
	<div class="border-top"></div>
</div>

<!-- services area -->
<section class="saasbox-features-area feature2 section-padding-120-90">
	<!-- Background Shape-->
	<div class="background-shape wow fadeInLeftBig" data-wow-duration="4000ms"></div>
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-12 col-md-9 col-lg-7 col-xxl-6">
				<div class="section-heading text-center"><i class="lni-brush"></i>
					<h2><span>Our Best </span> Solutions</h2>
					<p>Our services are perfect for fast growing tech and development companies who need to scale up their
						development capabilities while keeping quality, improving productivity and optimizing costs.
					</p>
				</div>
			</div>
		</div>
	</div>
	<div class="container">
		<div class="row align-items-center justify-content-center">
			<div class="col-12 col-lg-8 col-xxl-9">
				<div class="row g-4">
					<!-- Single Feature Area-->
					<div class="col-12 col-md-6">
						<div class="card feature-card wow fadeInUp" data-wow-delay="100ms" data-wow-duration="1000ms">
							<div class="card-body d-flex align-items-center"><i class="lni-blackboard"></i>
								<div class="fea-text">
									<h6>Business Analysis and Planning</h6>
									<span>Ultimate Solution for grow</span>
								</div>
							</div>
						</div>
					</div>
					<!-- Single Feature Area-->
					<div class="col-12 col-md-6">
						<div class="card feature-card wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1000ms">
							<div class="card-body d-flex align-items-center"><i class="lni lni-java"></i>
								<div class="fea-text">
									<h6>Application Design & Development</h6><span>Solution for Web & Mobile</span>
								</div>
							</div>
						</div>
					</div>
					<!-- Single Feature Area           -->
					<div class="col-12 col-md-6">
						<div class="card feature-card wow fadeInUp" data-wow-delay="300ms" data-wow-duration="1000ms">
							<div class="card-body d-flex align-items-center"><i class="lni lni-vector"></i>
								<div class="fea-text">
									<h6>UI/UX Design & Digital Branding</h6><span>Boot your sales</span>
								</div>
							</div>
						</div>
					</div>
					<!-- Single Feature Area  -->
					<div class="col-12 col-md-6">
						<div class="card feature-card wow fadeInUp" data-wow-delay="600ms" data-wow-duration="1000ms">
							<div class="card-body d-flex align-items-center"><i class="lni-rocket"></i>
								<div class="fea-text">
									<h6>Project and Delivery Management</h6><span>Launch Your App Faster & on Schedule</span>
								</div>
							</div>
						</div>
					</div>
					<!-- Single Feature Area           -->
					<div class="col-12 col-md-6">
						<div class="card feature-card wow fadeInUp" data-wow-delay="400ms" data-wow-duration="1000ms">
							<div class="card-body d-flex align-items-center"><i class="lni-wechat"></i>
								<div class="fea-text">
									<h6>Support and Enhancement</h6><span>Avoid Downtime & Increase Sales</span>
								</div>
							</div>
						</div>
					</div>
					<!-- Single Feature Area        -->
					<div class="col-12 col-md-6">
						<div class="card feature-card wow fadeInUp" data-wow-delay="500ms" data-wow-duration="1000ms">
							<div class="card-body d-flex align-items-center"><i class="lni-check-box"></i>
								<div class="fea-text">
									<h6>Testing and Quality Assurance</h6><span>Launch Tested, Reliable Apps</span>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
			<div class="col-12 col-sm-10 col-md-7 col-lg-4 col-xxl-3">
				<div class="video-card video2 border-0 mt-5 mt-lg-0 wow fadeInUp" data-wow-delay="700ms" data-wow-duration="1000ms">
					<div class="card-body p-0">
						<img src="assets/img/bg-img/hero-solutions.png" alt="">
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="saasbox-news-area section-padding-120-0">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-12 col-sm-9 col-lg-7 col-xxl-6">
				<div class="section-heading text-center">
					<h6>They trust us</h6>
					<h2>Our Clients</h2>
				</div>
			</div>
		</div>
	</div>
	<!-- partner area -->
<div class="our-partner-area section-padding-0-120">
	<div class="container">
		<div class="row">
			<div class="col-12">
				<div class="our-partner-slides owl-carousel">
					<div class="single-partner"><img src="assets/img/clients/tcs.png" alt=""></div>
					<div class="single-partner"><img src="assets/img/clients/bsc.png" alt=""></div>
					<div class="single-partner"><img src="assets/img/clients/tmcel.png" alt=""></div>
					<div class="single-partner"><img src="assets/img/clients/xinix.png" alt=""></div>
					<div class="single-partner"><img src="assets/img/clients/ssd.png" alt=""></div>
					<div class="single-partner"><img src="assets/img/clients/syfinity.png" alt=""></div>
					<div class="single-partner"><img src="assets/img/clients/millennium.png" alt=""></div>
					<div class="single-partner"><img src="assets/img/clients/d-telecom.png" alt=""></div>
					<div class="single-partner"><img src="assets/img/clients/planet-fitness.png" alt=""></div>
					<div class="single-partner"><img src="assets/img/clients/standard-bank.png" alt=""></div>
					<div class="single-partner"><img src="assets/img/clients/sabaas.png" alt=""></div>
					<div class="single-partner"><img src="assets/img/clients/siri-tech.png" alt=""></div>
					<div class="single-partner"><img src="assets/img/clients/bmw.png" alt=""></div>
					<div class="single-partner"><img src="assets/img/clients/isanqa.png" alt=""></div>
					<div class="single-partner"><img src="assets/img/clients/fournos.png" alt=""></div>
					<div class="single-partner"><img src="assets/img/clients/kwrx.png" alt=""></div>
					<div class="single-partner"><img src="assets/img/clients/techno.png" alt=""></div>
					<div class="single-partner"><img src="assets/img/clients/csg.png" alt=""></div>
					<div class="single-partner"><img src="assets/img/clients/futsal.png" alt=""></div>
					<div class="single-partner"><img src="assets/img/clients/studio-linear.png" alt=""></div>
					<div class="single-partner"><img src="assets/img/clients/ocs.png" alt=""></div>
					<div class="single-partner"><img src="assets/img/clients/artysta.png" alt=""></div>
				</div>
			</div>
		</div>
	</div>
</div>
</section>


<div class="container">
	<div class="border-top"></div>
</div>


<!-- facts area -->
<section class="cta-area bg-img bg-overlay section-padding-120 jarallax" style="background-image: url('assets/img/bg-img/1.jpg');">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-12 col-lg-8">
				<div class="cta-text text-center">
					<h2>Looking for a reliable app development partner ?</h2>
					<a class="btn saasbox-btn white-btn" routerLink="/contact">Schedule a Call</a>
				</div>
			</div>
		</div>
	</div>
</section>

<!-- cookie alert area -->
<div class="cookiealert mb-0" role="alert">
	<p>This site uses cookies. We use cookies to ensure you get the best experience on our website. For details, please
		check our <a routerLink="" target="_blank"> Privacy Policy.</a></p>
	<button class="btn btn-primary acceptcookies" type="button" aria-label="Close">I agree</button>
</div>