import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {path: '', redirectTo: 'welcome', pathMatch: 'full'},
  {path: '', loadChildren: () => import('./layouts/layouts.module').then(mod => mod.LayoutsModule)}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling : 'enabled'
  })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
