<div class="breadcrumb--area white-bg-breadcrumb">
	<div class="container h-50vh">
		<div class="row h-50vh align-items-center">
			<div class="col-12">
				<div class="breadcrumb-content">
					<h2 class="breadcrumb-title">Contact <span class="text-yellow">Us</span></h2>
				</div>
			</div>
		</div>
	</div>
</div>


<!-- contact area-->
<div class="saasbox-contact-us-area section-padding-120-40">
	<div class="container">
		<div class="row justify-content-between">
			<!-- contact side info-->
			<div class="col-12 col-lg-5 col-xl-4">
				<div class="contact-side-info mb-80">
					<p class="mb-4">Write to us or give us a call. We will reply to you as soon as possible. But yes, it can take
						up to 24 hours.</p>
					<div class="contact-mini-card-wrapper">
						<!-- contact mini card-->
						<div class="contact-mini-card">
							<div class="contact-mini-card-icon"><i class="lni lni-envelope"></i></div>
							<p>info@vibhumit.com</p>
						</div>
						<!-- contact mini card-->
						<div class="contact-mini-card">
							<div class="contact-mini-card-icon"><i class="lni lni-headphone-alt"></i></div>
							<p>+91 798-9964-680</p>
						</div>
						<!-- contact mini card-->
						<div class="contact-mini-card">
							<div class="contact-mini-card-icon"><i class="lni lni-map-marker"></i></div>
							<p>11-144/1, Polakampadu Guntur (Near Vijayawada), Andhra Pradesh India - 522501</p>
						</div>
						<!-- contact mini card-->
						<div class="contact-mini-card">
							<div class="contact-mini-card-icon"><i class="lni lni-tag"></i></div>
							<p>We are open from 9am — 5pm IST business days.</p>
						</div>
					</div>
				</div>
			</div>
			<!-- contact form-->
			<div class="col-12 col-lg-7">
				<div class="contact-form mb-80">
					<form id="contactForm" (submit)="sendEmail($event)">
						<div class="row">
							<div class="col-12 col-lg-6">
								<label class="form-label" for="name">Full Name:</label>
								<input class="form-control mb-30" id="name" type="text" placeholder="John Doe" value="" name="name" required>
							</div>
							<div class="col-12 col-lg-6">
								<label class="form-label" for="email">Email Address:</label>
								<input class="form-control mb-30" id="email" type="email" placeholder="youremail@gmail.com" name="email" value="" required>
							</div>
							<div class="col-12">
								<label class="form-label" for="subject">Topics:</label>
								<input class="form-control mb-30" id="subject" type="text" placeholder="Technical Question" name="subject" value="">
							</div>
							<div class="col-12">
								<label class="form-label" for="message">Message:</label>
								<textarea class="form-control mb-30" id="message" name="message" placeholder="Message"></textarea>
							</div>
							<div class="col-12 text-center">
								<button class="btn saasbox-btn w-100" type="submit">Send Now</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- google maps-->
<div class="google-maps-wrapper">

	<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15303.11161953026!2d80.59876528570564!3d16.486779820853343!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4a2754b7eb06146b!2sVibhumIT%20Solutions%20Private%20Limited!5e0!3m2!1sen!2sin!4v1627452602420!5m2!1sen!2sin" width="600"
		height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
</div>