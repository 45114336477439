<!-- cool header area -->
<div class="breadcrumb--area white-bg-breadcrumb">
	<div class="container h-50vh">
		<div class="row h-50vh align-items-center">
			<div class="col-12">
				<div class="breadcrumb-content">
					<h2 class="breadcrumb-title">Join <span class="text-yellow">Us</span></h2>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- career area -->
<div class="inner-page-wrapper section-padding-120">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-12 col-lg-8">
				<div class="section-heading text-center">
					<i class="lni-paperclip"></i>
					<h3><span class="mx-2 text-red">Choose a job you love,</span> and you will never have to work for a day in
						your life</h3>
					<br>
					<p>send your detailed CV to <a href="mailto:careers@vibhumit.com"><span class="mx-2 text-red">careers@vibhumit.com</span></a>
					</p>
				</div>
			</div>
			<!-- opening area-->
			<div class="faq--area section-padding-0-120">
				<div class="container">
					<div class="row g-5">
						<div class="col-12 col-lg-6">
							<div class="faq-content">
								<div class="accordion faq--accordian" id="faqaccordian">
									<!-- Single opening -->
									<div class="card border-0">
										<div class="card-header" id="headingOne">
											<button class="btn" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">1. Senior Full-Stack Developer
											</button>
										</div>
										<div class="collapse show" id="collapseOne" aria-labelledby="headingOne" data-parent="#faqaccordian">
											<div class="card-body">
												<p></p>
											</div>
										</div>
									</div>
									<!-- Single opening -->
									<div class="card border-0">
										<div class="card-header" id="headingThree">
											<button class="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">3. Senior DevOps Engineer
											</button>
										</div>
										<div class="collapse" id="collapseThree" aria-labelledby="headingThree" data-parent="#faqaccordian">
											<div class="card-body">
												<p></p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-12 col-lg-6">
							<div class="faq-content">
								<div class="accordion faq--accordian" id="faqaccordian2">
									<!-- Single opening -->
									<div class="card border-0">
										<div class="card-header" id="headingTwo">
											<button class="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">2. Senior React Native Developer
											</button>
										</div>
										<div class="collapse" id="collapseTwo" aria-labelledby="headingTwo" data-parent="#faqaccordian">
											<div class="card-body">
												<p></p>
											</div>
										</div>
									</div>
									<!-- Single opening-->
									<div class="card border-0">
										<div class="card-header" id="headingFour">
											<button class="btn" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">4. Senior Frontend Developer
											</button>
										</div>
										<div class="collapse show" id="collapseFour" aria-labelledby="headingFour" data-parent="#faqaccordian2">
											<div class="card-body">
												<p></p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>