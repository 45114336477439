<!-- cool header area -->
<div class="breadcrumb--area white-bg-breadcrumb">
	<div class="container h-50vh">
		<div class="row h-50vh align-items-center">
			<div class="col-12">
				<div class="breadcrumb-content">
					<h2 class="breadcrumb-title">About <span class="text-yellow">Company</span></h2>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- journey area -->
<div class="feature-area feature3 section-padding-120-70">
	<div class="container">
		<div class="row align-items-center justify-content-between">
			<div class="col-12 col-md-6 col-lg-5">
				<!-- feature section image-->
				<div class="feature-side-img mb-50">
					<img src="assets/img/bg-img/hero-journey.png" alt="">
				</div>
			</div>
			<div class="col-12 col-md-6">
				<!-- feature content-->
				<div class="feature--content mb-50">
					<h6 class="text-yellow">Our Journey from March 2016</h6>
					<h2>Our Humble Beginnings</h2>
					<p>
						Vibhum IT solutions is a start up based in India that aims to develop versatile and customized products that
						cater to the small, mid size and large business operations across the globe. Our expert team is focused in
						customer engagements to create and retain a competitive advantage by delivering ingenious solutions with
						increased operational efficiencies.
					</p>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- vision area -->
<section class="saasbox-team-area section-padding-120">
	<div class="container">
		<div class="row align-items-center justify-content-between">
			<div class="col-12 col-md-9 col-lg-6">
				<div class="section-heading white mb-5 mb-lg-0">
					<h2>Our Vision</h2>
					<p>To become a preferred service provider that delivers innovation and differentiation by leveraging technology and finest talent in the industry.</p>
				</div>
			</div>
			<div class="col-12 col-lg-6">
				<div class="feature-side-img mb-50 ml-10"><img src="assets/img/bg-img/hero-vision.png" alt=""></div>
			</div>
		</div>
	</div>
</section>

<!-- mission area -->
<section class="about-area about2 section-padding-120">
	<div class="container">
		<div class="row align-items-center justify-content-md-center justify-content-lg-between">
			<!-- about thumbnail area-->
			<div class="col-12 col-md-4 col-lg-6">
				<div class="aboutUs-thumbnail mt-120 wow fadeInUp" data-wow-delay="300ms" data-wow-duration="1000ms">
					<img width="80%" src="assets/img/bg-img/hero-mission.png" alt="">
				</div>
			</div>
			<!-- about us content area-->
			<div class="col-12 col-md-8 col-lg-6">
				<div class="aboutUs-content mt-120 wow fadeInUp" data-wow-delay="600ms" data-wow-duration="1000ms">
					<div class="section-heading mb-5">
						<h2>Our Mission</h2>
						<br>
						<p>To ensure our customers gain and sustain business advantage by providing innovative world-class solutions with minimal risk, competitive cost and flexible delivery model.</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<!-- values area -->
<section class="saasbox-team-area section-padding-120">
	<div class="container">
		<div class="row align-items-center justify-content-between">
			<div class="col-12 offset-lg-2 col-lg-4 ml-10">
				<div class="section-heading white mb-5 mb-lg-0 mr-5">
					<h2>Our Values </h2>

					<div class="feature3">
						<div class="feature--content mb-50">
							<ul class="pl-0 text-white ml-5">
								<li>Integrity</li>
								<li>Team work</li>
								<li>Consistent</li>
								<li>Commitment</li>
								<li>Quality</li>
								<li>Can Do Spirit</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div class="col-12 col-lg-6">
				<div class="feature-side-img mb-50 ml-10">
					<img src="assets/img/bg-img/hero-values.png" width="70%" alt="">
				</div>
			</div>

		</div>
	</div>
</section>


<!-- team area -->
<div class="feature-area feature3 section-padding-120-70">
	<div class="container">
		<div class="row align-items-center justify-content-between">
			<div class="col-12 col-md-6 col-lg-5">
				<!-- Feature Section Image-->
				<div class="feature-side-img mb-50">
					<img src="assets/img/bg-img/hero-2.png" alt="">
				</div>
			</div>
			<div class="col-12 col-md-6">
				<!-- Feature Content-->
				<div class="feature--content mb-50">
					<h6 class="text-yellow">Be a part of our story !</h6>
					<h2>Our Team</h2>
					<p>We partner with clients to provide complete IT infrastructure, support and expertise to maximize ROI and accelerate profit. Our team has strong product development experience and capabilities in delivering technical, operational and innovative solutions. We are
						Versatile, Vibrant and Visionary which makes our team and products stand out in the crowd.</p>
				</div>
			</div>
		</div>
	</div>
</div>
