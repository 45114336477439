<!-- footer area -->
<footer class="footer-area section-padding-120-0">
	<div class="container">
		<div class="row justify-content-between">
			<!-- footer widget area-->
			<div class="col-12 col-sm-10 col-lg-3">
				<div class="footer-widget-area mb-70">
					<a class="d-block mb-4" routerLink="welcome">
						<img src="assets/img/blocks/logo_dark.png" alt="vibhumit">
					</a>
				</div>
			</div>

			<!-- Footer Widget Area-->
			<div class="col-12 col-sm-6 col-md-4 col-lg-2">
				<div class="footer-widget-area mb-70">
					<h5 class="widget-title">Website Links</h5>
					<ul>
						<li><a routerLink="about-us">About Us</a></li>
						<li><a routerLink="services">Our Services</a></li>
						<li><a routerLink="career">Join our team</a></li>
					</ul>
				</div>
			</div>
			<!-- footer widget area-->
			<div class="col-12 col-sm-6 col-md-4 col-lg-2">
				<div class="footer-widget-area mb-70">
					<h5 class="widget-title">Important Links</h5>
					<ul>
						
						<li><a href="javascript:void(0);">Terms &amp; Conditions</a></li>
						<li><a href="javascript:void(0);">Privacy Policy</a></li>
						<li><a routerLink="contact">Help &amp; Support</a></li>
					</ul>
				</div>
			</div>
			<!-- Footer Widget Area-->
			<div class="col-12 col-sm-6 col-md-4 col-lg-3">
				<div class="footer-widget-area mb-70">
					<h5 class="widget-title">Subscribe</h5>
					<div class="newsletter-form">
						<form action="">
							<input class="form-control" type="email" placeholder="Enter email &amp; press enter">
							<button class="btn d-none" type="submit">Go</button>
						</form>
					</div>
					<!-- Footer Social Icon-->
					<div class="footer-social-icon d-flex align-items-center ">

						Follow Us &nbsp;&nbsp; <a href="https://www.linkedin.com/company/vibhum-it-solutions-pvt-ltd" data-toggle="tooltip" data-placement="top" title="Linkedin">
							<i class="fa fa-linkedin fa-lg"></i>
						</a>

					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="container">
		<div class="row align-items-center">
			<div class="col-12 text-center">
				<!-- copywrite text-->
				<div class="footer--content-text">
					<p class="mb-5">All rights reserved & copyright © by <a routerLink="welcome">VibhumIT 2016.</a>
					</p>
				</div>
			</div>
		</div>
	</div>
</footer>