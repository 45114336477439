<!-- Error Area-->
<div class="saasbox-error-area section-padding-0-120">
  <div class="container">
    <div class="row justify-content-center align-items-center">
      <div class="col-12 col-sm-10 col-md-9 col-lg-7">
        <img class="mb-5" src="assets/img/bg-img/hero-7.png" alt="">
        <h5>Oops! Page not found</h5>
        <p>We couldn't find any results for your search. Try again.</p>
        <!-- <a class="btn saasbox-btn mt-4" routerLink="/welcome">Back Home</a> -->
      </div>
    </div>
  </div>
</div>
<div class="container">
  <div class="border-top"></div>
</div>
