<!-- header area-->
<header class="header-area header2">
	<div class="container">
		<div class="classy-nav-container breakpoint-off">
			<nav class="classy-navbar justify-content-between" id="saasboxNav">
				<!-- logo-->
				<a class="nav-brand mr-5" routerLink="">
					<img src="assets/img/blocks/logo_dark.png" width="55%" alt="">
				</a>
				
				
				<!-- navbar toggler-->
				<div class="classy-navbar-toggler">
					<span class="navbarToggler">
						<span></span>
						<span></span>
						<span></span>
						<span></span>
					</span>
				</div>
				
				<!-- menu-->
				<div class="classy-menu">
					
					<!-- close btn-->
					<div class="classycloseIcon">
						<div class="cross-wrap"><span class="top"></span><span class="bottom"></span></div>
					</div>
					
					<!-- nav start-->
					<div class="classynav">
						<ul id="corenav">
							<li><a routerLink="">Home</a></li>
							<li><a routerLink="about-us">About Us</a></li>
							<li><a routerLink="services">Services</a></li>
							<li><a routerLink="career">Career</a></li>
							<!-- <li><a routerLink="process">How we deliver</a></li> -->
							<li><a routerLink="contact">Contact</a></li>
						</ul>
						
						<!-- login button-->
						<!-- <div class="login-btn-area ml-4 mt-4 mt-lg-0">
							<a class="btn saasbox-btn btn-sm" routerLink="">Login</a>
						</div> -->
					</div>
				</div>
			</nav>
		</div>
	</div>
</header>