<!-- cool header area -->
<div class="breadcrumb--area white-bg-breadcrumb">
	<div class="container h-50vh">
		<div class="row h-50vh align-items-center">
			<div class="col-12">
				<div class="breadcrumb-content">
					<h2 class="breadcrumb-title">Our <span class="text-yellow">Services</span></h2>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- service area -->
<section class="service-area section-padding-120 bg-white" id="service">
	<div class="container">
		<div class="row justify-content-center g-5">
			<!-- Single Service Area-->
			<div class="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-4">
				<div class="card service-card wow fadeInUp" data-wow-delay="100ms" data-wow-duration="1000ms">
					<div class="card-body">
						<div class="icon"><i class="lni-blackboard"></i></div>
						<h4>Business Analysis and Planning</h4>
					</div>
				</div>
			</div>
			<!-- Single Service Area-->
			<div class="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-4">
				<div class="card service-card active wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1000ms">
					<div class="card-body">
						<div class="icon"><i class="lni-layout"></i></div>
						<h4>Modern Website Developement</h4>
					</div>
				</div>
			</div>
			<!-- Single Service Area-->
			<div class="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-4">
				<div class="card service-card wow fadeInUp" data-wow-delay="300ms" data-wow-duration="1000ms">
					<div class="card-body">
						<div class="icon"><i class="lni-invention"></i></div>
						<h4>UI UX Designing &amp; Digital Branding</h4>
					</div>
				</div>
			</div>
			<!-- Single Service Area-->
			<div class="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-4">
				<div class="card service-card wow fadeInUp" data-wow-delay="400ms" data-wow-duration="1000ms">
					<div class="card-body">
						<div class="icon"><i class="lni-code"></i></div>
						<h4>Testing and Quality Assurance</h4>
					</div>
				</div>
			</div>
			<!-- Single Service Area-->
			<div class="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-4">
				<div class="card service-card wow fadeInUp" data-wow-delay="500ms" data-wow-duration="1000ms">
					<div class="card-body">
						<div class="icon"><i class="lni-cloud-check"></i></div>
						<h4>Testing and Quality Assurance</h4>
					</div>
				</div>
			</div>
			<!-- Single Service Area-->
			<div class="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-4">
				<div class="card service-card wow fadeInUp" data-wow-delay="600ms" data-wow-duration="1000ms">
					<div class="card-body">
						<div class="icon"><i class="lni-support"></i></div>
						<h4>Support and Enhancement</h4>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<!--  technology area -->
<div class="used-technology-area section-padding-120 bg-gray">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-12 col-lg-7 col-xxl-6">
				<div class="section-heading text-center">
					<h2><span class="mr-2">Specializations </span></h2>
				</div>
			</div>
		</div>

		<div class="container">
			<div class="hero--content--area">
				<div class="row justify-content-center g-4">
					<!-- Single Hero Card-->
					<div class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-6">
						<div class="card hero-cards h-100 border-0 wow fadeInUp p-3" data-wow-delay="100ms" data-wow-duration="1000ms">
							<div class="display-flex">
								<i class="lni lni-code-alt"></i>
								<h5>Development</h5>
							</div>
						</div>
					</div>
					<!-- Single Hero Card-->
					<div class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-6">
						<div class="card hero-cards h-100 border-0 wow fadeInUp p-3" data-wow-delay="300ms" data-wow-duration="1000ms">
							<div class="display-flex">
								<img src="assets/icon/support.png" alt="">
								<h5>Application Support & Maintenance</h5>
							</div>
						</div>
					</div>
					<!-- Single Hero Card-->
					<div class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-6">
						<div class="card hero-cards h-100 border-0 wow fadeInUp p-3" data-wow-delay="100ms" data-wow-duration="1000ms">
							<div class="display-flex">
								<img src="assets/icon/consultation.png" alt="">
								<h5>Consulting</h5>
							</div>
						</div>
					</div>
					<!-- Single Hero Card-->
					<div class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-6">
						<div class="card hero-cards h-100 border-0 wow fadeInUp p-3" data-wow-delay="300ms" data-wow-duration="1000ms">
							<div class="display-flex">
								<img src="assets/icon/usability.png" alt="">
								<h5>Testing</h5>
							</div>
						</div>
					</div>
					<!-- Single Hero Card-->
					<div class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-6">
						<div class="card hero-cards h-100 border-0 wow fadeInUp p-3" data-wow-delay="100ms" data-wow-duration="1000ms">
							<div class="display-flex">
								<i class="lni lni-handshake"></i>
								<h5>Bespoke</h5>
							</div>
						</div>
					</div>
					<!-- Single Hero Card-->
					<div class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-6">
						<div class="card hero-cards h-100 border-0 wow fadeInUp p-3" data-wow-delay="300ms" data-wow-duration="1000ms">
							<div class="display-flex">
								<i class="lni lni-cloud-check"></i>
								<h5>Cloud Services</h5>
							</div>
						</div>
					</div>
					<!-- Single Hero Card-->
					<div class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-6">
						<div class="card hero-cards h-100 border-0 wow fadeInUp p-3" data-wow-delay="100ms" data-wow-duration="1000ms">
							<div class="display-flex">
								<img src="assets/icon/webs.png" alt="">
								<h5>Application Managed Services</h5>
							</div>
						</div>
					</div>
					<!-- Single Hero Card-->
					<div class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-6">
						<div class="card hero-cards h-100 border-0 wow fadeInUp p-3" data-wow-delay="300ms" data-wow-duration="1000ms">
							<div class="display-flex">
								<img src="assets/icon/computer.png" alt="">
								<h5>Cloud Software Services</h5>
							</div>
						</div>
					</div>
					<!-- Single Hero Card-->
					<div class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-6">
						<div class="card hero-cards h-100 border-0 wow fadeInUp p-3" data-wow-delay="100ms" data-wow-duration="1000ms">
							<div class="display-flex">
								<img src="assets/icon/optimization.png" alt="">
								<h5>Application Modernization & Optimization</h5>
							</div>
						</div>
					</div>
					<!-- Single Hero Card-->
					<div class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-6">
						<div class="card hero-cards h-100 border-0 wow fadeInUp p-3" data-wow-delay="300ms" data-wow-duration="1000ms">
							<div class="display-flex">
								<i class="lni lni-cloud-sync"></i>
								<h5>Cloud Migration & Management</h5>
							</div>
						</div>
					</div>
					<!-- Single Hero Card-->
					<div class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-6">
						<div class="card hero-cards h-100 border-0 wow fadeInUp p-3" data-wow-delay="100ms" data-wow-duration="1000ms">
							<div class="display-flex">
								<img src="assets/icon/infinity.png" alt="">
								<h5>Devops</h5>
							</div>
						</div>
					</div>
					<!-- Single Hero Card-->
					<div class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-6">
						<div class="card hero-cards h-100 border-0 wow fadeInUp p-3" data-wow-delay="300ms" data-wow-duration="1000ms">
							<div class="display-flex">
								<img src="assets/icon/sketch.png" alt="">
								<h5>Enterprise Architecture Services</h5>
							</div>
						</div>
					</div>
					<!-- Single Hero Card-->
					<div class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-6">
						<div class="card hero-cards h-100 border-0 wow fadeInUp p-3" data-wow-delay="100ms" data-wow-duration="1000ms">
							<div class="display-flex">
								<img src="assets/icon/web copy.png" alt="">
								<h5>Web Applications</h5>
							</div>
						</div>
					</div>
					<!-- Single Hero Card-->
					<div class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-6">
						<div class="card hero-cards h-100 border-0 wow fadeInUp p-3" data-wow-delay="300ms" data-wow-duration="1000ms">
							<div class="display-flex">
								<img src="assets/icon/api.png" alt="">
								<h5>API Integrations</h5>
							</div>
						</div>
					</div>
					<!-- Single Hero Card-->
					<div class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-6">
						<div class="card hero-cards h-100 border-0 wow fadeInUp p-3" data-wow-delay="300ms" data-wow-duration="1000ms">
							<div class="display-flex">
								<img src="assets/icon/mob-apps.png" alt="">
								<h5>Mobile Applications</h5>
							</div>
						</div>
					</div>
					<!-- Single Hero Card-->
					<div class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-6">
						<div class="card hero-cards h-100 border-0 wow fadeInUp p-3" data-wow-delay="300ms" data-wow-duration="1000ms">
							<div class="display-flex">
								<i class="lni-star-half"></i>
								<h5></h5>
							</div>
						</div>
					</div>
					
				</div>
			</div>
		</div>


	</div>
</div>